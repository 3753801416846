<!-- Start of Banner section
        ============================================= -->
<section id="dia-banner" class="dia-banner-section position-relative hd-img">
    <div class="container">
        <div class="row">

            <div class="col-md-2">

            </div>

            <div class="col-md-8">
                <div class="dia-banner-content dia-headline pera-content">
                    <span class="dia-banner-tag">Do you struggle or worry about making sales?</span>
                    <h1 class="cd-headline clip is-full-width">Become a Sales Wizard in 5 days.
                    </h1>
                    <p>This program is designed to help you acquire the practical skill (know-how) require for
                        implementing Digital Marketing Strategy for any business.</p>
                    <div class="dia-banner-btn">
                        <div class="dia-play-btn text-center d-inline-block">
                            <a href="https://www.youtube.com/watch?v=ykcQ8ZqPP8o" class="lightbox-image overlay-box"><i
                                    class="fas fa-play"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-2">

            </div>
        </div>
    </div>
    <div class="banner-shape2 position-absolute" data-parallax='{"y" : 100, "rotateY":500}'><img
            src="assets/img/d-agency/shape/shp1.png" alt=""></div>

</section>
<!-- End of Banner section
        ============================================= -->


<!-- mini info -->
<div class="mmt">
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <div class="cl1">
                    <span class="far fa-calendar-alt icofont"></span>
                    <div class="align-right">
                        <span class="strong">DATE</span>
                        <span class="date">22nd - 26th Nov. 2021</span>
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <div class="cl1">
                    <span class="far fa-clock icofont"></span>
                    <div class="align-right">
                        <span class="strong">TIME</span>
                        <span class="date">8pm - 10pm Daily</span>
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <div class="cl1">
                    <span class="far fa-compass icofont"></span>
                    <div class="align-right">
                        <span class="strong">LOCATION</span>
                        <span class="date">ZOOM</span>
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <div class="cl1">
                    <span class="far fa-money-bill-alt icofont"></span>
                    <div class="align-right">
                        <span class="strong">FEE</span>
                        <span class="date">N5,000</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- end mini info -->


<!-- Start of Service section
        ============================================= -->
<section id="dia-service" class="dia-service-section">
    <div class="container">
        <div class="dia-service-content">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="dia-service-img position-relative">
                        <img src="assets/img/d-agency/service/s1.png" alt="">
                        <div class="dia-service-shape dia-service-shape1 position-absolute"><img
                                src="assets/img/d-agency/service/s2.png" alt=""></div>
                        <div class=" dia-service-shape dia-service-shape2 position-absolute"><img
                                src="assets/img/d-agency/service/s3.png" alt=""></div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="dia-service-text">
                        <div class="dia-section-title text-left text-capitalize dia-headline">
                            <h2>What will you learn?</h2>
                            <p class="para">Deep secret of the following 8 Modules:</p>
                        </div>

                        <div class="dia-service-details clearfix">
                            <div class="dia-service-item dia-headline ul-li-block wow fadeFromUp" data-wow-delay="0ms"
                                data-wow-duration="1500ms">
                                <h3>Day 1</h3>
                                <ul>
                                    <li>Facebook Marketing</li>
                                    <li>Instagram Marketing</li>
                                </ul>
                            </div>

                            <div class="dia-service-item dia-headline ul-li-block wow fadeFromUp" data-wow-delay="300ms"
                                data-wow-duration="1500ms">
                                <h3>Day 2</h3>
                                <ul>
                                    <li>WhatsApp Marketing</li>
                                    <li>Email Marketing</li>
                                </ul>
                            </div>
                            <div class="dia-service-item dia-headline ul-li-block wow fadeFromUp" data-wow-delay="600ms"
                                data-wow-duration="1500ms">
                                <h3>Day 3</h3>
                                <ul>
                                    <li>Affiliate Marketing</li>
                                    <li>Brand Positioning</li>
                                </ul>
                            </div>
                            <div class="dia-service-item dia-headline ul-li-block wow fadeFromUp" data-wow-delay="600ms"
                                data-wow-duration="1500ms">
                                <h3>Day 4</h3>
                                <ul>
                                    <li>Google Marketing</li>
                                    <li>Youtube Marketing</li>
                                </ul>
                            </div>

                            <div class="dia-service-item dia-headline ul-li-block wow fadeFromUp" data-wow-delay="600ms"
                                data-wow-duration="1500ms">
                                <h3>Day 5</h3>
                                <ul>
                                    <li>Revision</li>
                                    <li>General Question & Answer</li>
                                </ul>
                            </div>
                        </div>
                        <div class="dia-service-btn">
                            <div class="dia-service-more text-center float-left">
                                <a href="#register">Register Now</a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End of Service section
        ============================================= -->



<section class="work-category pad-tb tilt3d">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 v-center">
                <div class="common-heading text-l">
                    <h2 class="tw">Who should Attend?</h2>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-6">
                        <p class="white"><span class="far fa-check-circle icofont2"></span> Business Owner</p>
                        <p class="white"><span class="far fa-check-circle icofont2"></span> Brand Influencer</p>
                        <p class="white"><span class="far fa-check-circle icofont2"></span> Drop-shipper</p>
                        <p class="white"><span class="far fa-check-circle icofont2"></span> Marketing Executive</p>
                    </div>
                    <div class="col-md-6 col-sm-6">
                        <p class="white"><span class="far fa-check-circle icofont2"></span> Business Developer</p>
                        <p class="white"><span class="far fa-check-circle icofont2"></span> Job Seeker</p>
                        <p class="white"><span class="far fa-check-circle icofont2"></span> Business Adviser</p>
                        <p class="white"><span class="far fa-check-circle icofont2"></span> Student</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-7">
                <div class="dia-service-img position-relative">
                    <img src="assets/img/d-agency/service/s4.png" alt="">
                </div>
            </div>
        </div>
    </div>
</section>



<!-- Start of Team section
        ============================================= -->
<section id="dia-team" class="dia-team-section">
    <div class="container">
        <div class="dia-section-title text-center text-capitalize pera-content dia-headline">
            <!-- <span>Our Experts</span> -->
            <h2>Our Trainers</h2>
            <p>Our Trainers are expects from different aspect business sectors.</p>
        </div>
        <div id="dia-team-slide" class="dia-team-content owl-carousel">
            <div class="dia-team-pic-text wow fadeFromLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                <div class="dia-team-img position-relative">
                    <div class="team-mem-img-ei text-center position-relative">
                        <img src="assets/img/d-agency/team/tm1.png" alt="">
                        <span class="mshape-bg shape-bg1"><img src="assets/img/d-agency/team/msbg1.png" alt=""></span>
                        <span class="mshape-bg shape-bg2"><img src="assets/img/d-agency/team/msbg2.png" alt=""></span>
                    </div>
                    <div class="dia-team-social">
                        <!-- <a href="#"><i class="fab fa-facebook-f "></i></a>
                        <a href="#"><i class="fab fa-instagram"></i></a>
                        <a href="#"><i class="fab fa-twitter"></i></a> -->
                        <a href="https://www.linkedin.com/in/akibu-ismail-35068661/"><i class="fab fa-linkedin"></i></a>
                    </div>
                </div>
                <div class="dia-team-text dia-headline text-center pera-content">
                    <h3><a href="#">Akibu Ismail</a></h3>
                    <p>COO, Spangler Market</p>
                </div>
            </div>
            <div class="dia-team-pic-text wow fadeFromLeft" data-wow-delay="600ms" data-wow-duration="1500ms">
                <div class="dia-team-img position-relative">
                    <div class="team-mem-img-ei text-center position-relative">
                        <img src="assets/img/d-agency/team/tm2.png" alt="">
                        <span class="mshape-bg shape-bg1"><img src="assets/img/d-agency/team/msbg1.png" alt=""></span>
                        <span class="mshape-bg shape-bg2"><img src="assets/img/d-agency/team/msbg2.png" alt=""></span>
                    </div>
                    <div class="dia-team-social">
                        <a href="https://www.facebook.com/purity.adeyanju"><i class="fab fa-facebook-f "></i></a>
                        <!-- <a href="#"><i class="fab fa-instagram"></i></a>
                        <a href="#"><i class="fab fa-linkedin"></i></a>
                        <a href="#"><i class="fab fa-twitter"></i></a> -->
                    </div>
                </div>
                <div class="dia-team-text dia-headline text-center pera-content">
                    <h3><a href="#">Purity Abhulimhen</a></h3>
                    <p>CEO, Spangler Market</p>
                </div>
            </div>
            <div class="dia-team-pic-text wow fadeFromLeft" data-wow-delay="900ms" data-wow-duration="1500ms">
                <div class="dia-team-img position-relative">
                    <div class="team-mem-img-ei text-center position-relative">
                        <img src="assets/img/d-agency/team/tm3.png" alt="">
                        <span class="mshape-bg shape-bg1"><img src="assets/img/d-agency/team/msbg1.png" alt=""></span>
                        <span class="mshape-bg shape-bg2"><img src="assets/img/d-agency/team/msbg2.png" alt=""></span>
                    </div>
                    <div class="dia-team-social">
                        <a href="https://www.facebook.com/sulaimon.ishola.129"><i class="fab fa-facebook-f "></i></a>
                        <!-- <a href="#"><i class="fab fa-instagram"></i></a>
                        <a href="#"><i class="fab fa-linkedin"></i></a>
                        <a href="#"><i class="fab fa-twitter"></i></a> -->
                    </div>
                </div>
                <div class="dia-team-text dia-headline text-center pera-content">
                    <h3><a href="#">Sulaimon Olaranwaju</a></h3>
                    <p>Business/Life Coach</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End of Team section
        ============================================= -->

<!-- Start of testimonial section
        ============================================= -->
<!-- <section id="dia-testimonial" class="dia-testimonial-section position-relative">
    <div class="tst-side-shape position-absolute" data-parallax='{"y" : 50}'> <img
            src="assets/img/d-agency/shape/tsts1.png" alt=""></div>
    <div class="container">
        <div class="dia-section-title text-center text-capitalize dia-headline">
            <span>Client’s Testimonials</span>
            <h2>Client’s review for our work satisfaction.</h2>
        </div>
        <div class="dia-testimonial_slider-area position-relative">
            <div class="test-shape1 position-absolute wow fadeFromRight" data-wow-delay="300ms"
                data-wow-duration="1500ms"> <img src="assets/img/d-agency/shape/tbg1.png" alt=""></div>
            <div class="test-shape2 position-absolute wow fadeFromLeft" data-wow-delay="300ms"
                data-wow-duration="1500ms"> <img src="assets/img/d-agency/shape/tbg2.png" alt=""></div>
            <div id="dia-testimonial_slide" class="carousel slide" data-ride="carousel">
                <div class="dia-testimonial_indicator relative-position">
                    <ol class="carousel-indicators">
                        <li data-target="#dia-testimonial_slide" data-slide-to="0" class="active">
                            <img src="assets/img/d-agency/testimonial/tst1.png" alt="">
                        </li>
                        <li data-target="#dia-testimonial_slide" data-slide-to="1">
                            <img src="assets/img/d-agency/testimonial/tst2.png" alt="">
                        </li>
                        <li data-target="#dia-testimonial_slide" data-slide-to="2">
                            <img src="assets/img/d-agency/testimonial/tst3.png" alt="">
                        </li>
                    </ol>
                </div>
                <div class="carousel_preview">
                    <div class="carousel-inner relative-position">
                        <div class="carousel-item active">
                            <div class="dia-testimonial_content">
                                <div class="dia-testimonial_rating ul-li">
                                    <ul>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                    </ul>
                                </div>
                                <div class="dia-testimonial_text relative-position pera-content dia-headline">
                                    <p>We are very impressed by the Century Information Systems team working on our
                                        Enterprise Resource Planning. They continually exceed our expectations in the
                                        areas of
                                        code quality, project management, and quality assurance.
                                    </p>
                                </div>
                                <div class="dia-testimonial_name_designation">
                                    <div class="dia-testimonial_meta dia-headline pera-content">
                                        <h4>T.E.A.M Nigeria</h4>
                                        <p>IT Manager</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="dia-testimonial_content">
                                <div class="dia-testimonial_rating ul-li">
                                    <ul>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                    </ul>
                                </div>
                                <div class="dia-testimonial_text relative-position pera-content dia-headline">
                                    <p>I have been directing web, online marketing and eCommerce applications for over
                                        15 years and this was my first job with Century Information Systems. I was
                                        completely
                                        satisfied and impressed with their level of professionalism, proactive project
                                        management and technical capabilities. They are the real deal, the complete
                                        package, and now my primary mobile and web development company.
                                    </p>
                                </div>
                                <div class="dia-testimonial_name_designation">
                                    <div class="dia-testimonial_meta dia-headline pera-content">
                                        <h4>Olumawu College</h4>
                                        <p>Principal</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="dia-testimonial_content">
                                <div class="dia-testimonial_rating ul-li">
                                    <ul>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                    </ul>
                                </div>
                                <div class="dia-testimonial_text relative-position pera-content dia-headline">
                                    <p>Century Information Systems did an outstanding job on this project. We will be
                                        using their
                                        services again and highly recommend them. The team worked well. The product was
                                        in the right quality. Feedback was received and issue were corrected rapidly.
                                        Their management processes compliment the solid development skills they bring to
                                        the table. I'm grateful for being able to work with them on this project.
                                    </p>
                                </div>
                                <div class="dia-testimonial_name_designation">
                                    <div class="dia-testimonial_meta dia-headline pera-content">
                                        <h4>High Court of Justice, F.C.T Abuja</h4>
                                        <p>Director of Probate</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dia-testimonial_indicator-dot">
                    <ol class="carousel-indicators2">
                        <li data-target="#dia-testimonial_slide" data-slide-to="0" class="active">
                        </li>
                        <li data-target="#dia-testimonial_slide" data-slide-to="1">
                        </li>
                        <li data-target="#dia-testimonial_slide" data-slide-to="2">
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End of testimonial section
        ============================================= -->



<!-- Start of newslatter section
        ============================================= -->
<section id="register" class="dia-newslatter-section position-relative">
    <div class="newslatter-shape position-absolute n-shape1" data-parallax='{"x" : 30}'><img
            src="assets/img/d-agency/newslatter/nv1.png" alt=""></div>
    <div class="newslatter-shape position-absolute n-shape2" data-parallax='{"x" : -30}'><img
            src="assets/img/d-agency/newslatter/nv2.png" alt=""></div>
    <div class="newslatter-shape position-absolute n-shape3" data-parallax='{"x" : -30}'><img
            src="assets/img/d-agency/newslatter/nv3.png" alt=""></div>
    <div class="newslatter-shape position-absolute n-shape4" data-parallax='{"y" : 30}'><img
            src="assets/img/d-agency/newslatter/nv4.png" alt=""></div>
    <div class="newslatter-shape position-absolute n-shape5" data-parallax='{"y" : 30}'><img
            src="assets/img/d-agency/newslatter/nv5.png" alt=""></div>
    <div class="newslatter-shape position-absolute n-shape6" data-parallax='{"x" : -30}'><img
            src="assets/img/d-agency/newslatter/nv6.png" alt=""></div>
    <div class="container">
        <div class="row">
            <div class="col-lg-7">
                <div class="common-heading text-l">
                    <h2 class="mt0">Register Now</h2>
                    <p class="fpd">You're one step away from becoming a sales Wizard.</p>
                </div>
                <div class="form-block">
                    <form #form="ngForm" name="feedback-form" (submit)="submitForm()">
                        <div class="fieldsets row">
                            <div class="col-md-12"><input type="text" placeholder="First Name" name="firstname"
                                    [(ngModel)]="obj.firstname">
                            </div>
                            <div class="col-md-12"><input type="text" placeholder="Last Name" name="lastname"
                                [(ngModel)]="obj.lastname"></div>
                        </div>
                        <div class="fieldsets row">
                            <div class="col-md-12"><input type="text" placeholder="WhatsApp Number" name="phone"
                                    [(ngModel)]="obj.phone"></div>
                            <!-- <div class="col-md-12"><input type="text" placeholder="Email Address" name="email"
                                [(ngModel)]="obj.email"></div> -->
                        </div>
                        <div class="fieldsets mt20"> <button type="submit" name="submit"
                                class="lnk btn-main bg-btn">Submit <i class="fas fa-chevron-right fa-icon"></i><span
                                    class="circle"></span></button> </div>
                        <p class="trm"><i class="fas fa-lock"></i>We hate spam, and we respect your privacy.</p>
                    </form>
                </div>
            </div>

            <div class="col-lg-5">
                <div class="common-heading text-l">
                    <h2 class="mt0">Payment Info</h2>
                </div>

                <div class="payd">
                    <p class="fpd">Bank Name<br>Wema Bank</p>
                    <p class="fpd">Account Name<br>Spangler Market</p>
                    <p class="fpd">Account Number<br>7991662548</p>
                    <p class="fpd">Amount<br><span class="fpda">N5,000</span></p>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="crm-call-to-action clearfix">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <h3 class="mb30">For more information call</h3>
            </div>
            <div class="col-md-2">
                <div class="btn btn-outline">
                    <a href="tel:08142597752">+234 814 259 7752</a>
                </div>
            </div>

            <div class="col-md-2">
                <div class="btn btn-outline">
                    <a href="tel:08035276278">+234 803 5276 278</a>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- End of newslatter section
                ============================================= -->